import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage1 from "../../components/WelcomeImage1";
import ImageText4 from "../../components/ImageText4";
import ImageText5 from "../../components/ImageText5";
import ImageText6 from "../../components/ImageText6";
import ImageText2 from "../../components/ImageText2";
import ImageText1 from "../../components/ImageText1";
import Footer from "../../components/Footer";
import CardImage from "./CardImage";
import Contact from "./Contact";

import backgroundImage from "../../assets/images/Klus & Tuin Background Image.png";
import imageUrl1 from "../../assets/images/Klus & Tuein 2.png";
import imageUrl2 from "../../assets/images/Klus & Tuein 1.png";
import imageUrl3 from "../../assets/images/Klus & Tuein 3.png";
import imageUrl4 from "../../assets/images/Klus & Tuein 5.png";
import imageUrl5 from "../../assets/images/Klus & Tuein 4.png";
import imageUrl6 from "../../assets/images/Klus & Tuein 6.png";
import imageUrl7 from "../../assets/images/Klus & Tuein 8.png";
import imageUrl8 from "../../assets/images/Klus & Tuein 7.png";
import imageUrl9 from "../../assets/images/Klus & Tuein 9.png";
import src from "../../assets/images/Klus & Tuein 10.png";
import imageUrl10 from "../../assets/images/Klus & Tuein 11.png";
import filedown from "../../assets/files/240501-VLOK-Uitvoeringsvoorwaarden-2024-huisstijl.pdf";

function KlusTuin() {
  const welcomeHeading = <>Klus & Tuin</>;

  const imageTextText = (
    <>
      Als detacherings- en werving & selectie bureau plaatsen wij al jaren onze
      vakmensen bij projecten in het bedrijfsleven, variërend van industriële
      projecten tot aan bouwprojecten en meer.
      <br />
      <br />
      Nu is het al zo dat er op vele vakgebieden grote tekorten zijn aan
      specialisten in het bedrijfsleven en ondanks dat de overheid het
      stimuleert dit de komende (tientallen?) jaren zeker niet navenant zal
      worden opgelost.
    </>
  );
  const imageTextText1 = (
    <>
      Recent bespraken wij in een overleg de problematiek in de markt
      hieromtrent zowel qua bedrijfsleven maar vooral ook vanuit de particuliere
      hoek.
      <br />
      <br />
      Steeds vaker wordt ons gevraagd of wij met onze vakmensen niet ook iets
      voor de particulieren zouden willen gaan doen. Iedereen kent het wel, men
      vraagt rond om zijn of haar tuin te onderhouden of te verbouwen, men
      googled naar vakmensen voor onderhoud en verbouwingen etc., men wil een
      nieuw straatje leggen, schilderen, stucen etc.
      <br />
      <br />
      Vaak worden voor dit soort klussen torenhoge tarieven gevraagd en vaak
      duurt het lang eer men kan starten of is het geheel onduidelijk wanneer er
      een mogelijkheid is om het te doen. Tevens voedt dit dan ook weer de
      prijzen omdat men toch snel geholpen wilt worden.
      <br />
      <br />
      Natuurlijk heeft dit allemaal te maken met het tekort aan vakmensen en
      zitten de agenda's van de aannemers helemaal vol.
    </>
  );
  const imageTextText2 = (
    <>
      Het duurde derhalve niet lang totdat het kwartje bij ons viel. Wat nou als
      wij met onze vakspecialisten ook een apart bedrijf gaan starten voor
      klussen bij mensen thuis. Wij beschikken over een grote database aan
      gecertificeerde vakbekwame timmerlieden, stratenmakers, stucadoors,
      hoveniers, schilders en ga zo maar door. Door dit grote aanbod aan
      specialisten kunnen wij redelijk snel schakelen en hoeft het niet maanden
      te duren voordat er aan uw klus kan worden begonnen. Onze insteek ik ook,
      juist omdat het de particuliere markt betreft, de tarieven op een normaal
      betaalbaar niveau te houden. De nieuwe tak "DD Klus & Tuin" is bedacht en
      kan beginnen. U kunt vanaf heden uw aanvraag indienen via het contact
      formulier.
      <br />
      <br />
      De procedure van aanvraag tot voltooiing van de klus staat hieronder in
      effectieve stappen beschreven.
    </>
  );
  const imageTextText3 = (
    <>
      Als u de stappen heeft doorgenomen en de klus middels een aanvraag bij ons
      wilt voorleggen, vul dan het formulier in waarin de stappen zijn
      toegevoegd. Als u het formulier heeft ingevuld en eventuele informatie
      middels foto's of een beschrijving heeft toegevoegd, druk dan op
      verzenden.
      <br />
      <br />
      Wij ontvangen dan uw aanvraag in onze mailbox en zullen zo snel als
      mogelijk contact met u opnemen om een opzichter bij u langs te sturen ter
      overleg en opname van het project (kosteloos)
    </>
  );
  const imageTextText4 = (
    <>
      DD Klus & Tuin is lid van VLOK, de branchevereniging voor vakmensen. Alle
      VLOK-leden zijn aangesloten bij de geschillencommissie Klussen- en
      Vloerenbedrijven. Deze onafhankelijke geschillencommissie is tot stand
      gekomen op initiatief van VLOK in samenwerking met de Consumentenbond en
      Vereniging Eigen Huis.
      <br />
      <br />
      Mochten er geschillen na oplevering van een klus alsnog ontstaan gelden de
      algemene VLOK uitvoeringsvoorwaarden voor vakmensen 2024 waar u bij de
      opname ook voor meetekend. Hiermede is de klus volledig gevrijwaard van
      toekomstige problemen en u bent verzekerd van A tot Z.
      <br />
      <br />
      De voorwaarden leest u op deze pagina. En daaronder kunt u uw aanvraag
      indienen inclusief de gevraagde documenten. Wij zien u graag binnenkort
      tegemoet !
    </>
  );
  const buttonText = "Algemene Voorwaarden";
  return (
    <div>
      <Helmet>
        <title>Werving & Selectie</title>
        <meta
          name="description"
          content="DD-Group biedt gespecialiseerde wervings- en detacheringdiensten in verschillende sectoren, waaronder industrie, bouw en logistiek. Wij bieden nu ook 'DD-Group Klus & Tuin' voor particulieren, waarmee je vakbekwame professionals kunt vinden voor thuisprojecten tegen betaalbare tarieven."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage1
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <ImageText4
            imageUrl1={imageUrl1}
            imageUrl2={imageUrl2}
            text={imageTextText}
            backgroundColor="#D9D9D9"
            textColor="black"
          />
          <ImageText5
            imageUrl1={imageUrl3}
            imageUrl2={imageUrl4}
            imageUrl3={imageUrl5}
            text={imageTextText1}
          />
          <ImageText6
            imageUrl1={imageUrl6}
            imageUrl2={imageUrl7}
            imageUrl3={imageUrl8}
            text={imageTextText2}
            backgroundColor="#D9D9D9"
            textColor="black"
          />
          <ImageText2 imageUrl={imageUrl9} text={imageTextText3} />
          <ImageText1
            imageUrl={imageUrl10}
            text={imageTextText4}
            backgroundColor="#D9D9D9"
            textColor="black"
            buttonText={buttonText}
            openInNewTab={true}
            downloadFile={filedown}
          />
          <CardImage src={src} />
          <Contact />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default KlusTuin;
